<template>
  <UiPopup
    :model-value="modelValue"
    title="Add country code"
    description="To start first call, please add a country code"
    primary-button-text="Add code"
    size="small"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <form ref="form" @submit.prevent>
      <UiInputPhone
        v-model="shallowPhone"
        :important="shallowPhone.phone_country_id"
        :lead="lead"
        name="phone"
        placeholder="Add a number"
        class="flex justify-center"
        :error="useGetFieldErrors(v$, ['phone_country_id', 'phone'])"
      ></UiInputPhone>
    </form>
  </UiPopup>
</template>

<script setup lang="ts">
import { useVuelidate } from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import type { Phone, Lead } from '@/types'

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  lead: Lead
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const form = ref(null)
const shallowPhone = ref<Phone>({
  phone_country_id: undefined,
  phone: undefined,
})
watch(
  () => props.lead,
  (value) => {
    shallowPhone.value = JSON.parse(JSON.stringify(value.phone))
  },
  { immediate: true }
)

const validatePhone = () => {
  return useValidatePhone(shallowPhone.value.phone, shallowPhone.value.phone_country_id)
}

const rules = computed(() => ({
  phone: {
    required: helpers.withMessage('Phone is required', required),
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
  phone_country_id: {
    required: helpers.withMessage('Country code is required', required),
    validatePhone: helpers.withMessage('Phone number is not valid', validatePhone),
  },
}))

const v$ = useVuelidate(rules, shallowPhone)

const submit = async () => {
  const isValid = await v$.value.$validate()
  if (isValid) {
    emits('input', shallowPhone.value)
    emits('update:modelValue', false)
  }
}
</script>

<style scoped></style>
